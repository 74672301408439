import {
  GET_LIST,
  GET_LIST_SUCCESS,
  GET_LIST_FAILED ,
  GET_COUNT,
  GET_COUNT_SUCCESS,
  GET_COUNT_FAILED,
  GET_EXPORT,
  GET_EXPORT_SUCCESS,
  GET_EXPORT_FAILED,

  SET_INVOICE_UPDATE,
  SET_INVOICE_UPDATE_SUCCESS,
  SET_INVOICE_UPDATE_FAILED,
  SET_INVOICE_UPDATE_RESET,

  GET_INVOICE_UPDATE,
  GET_INVOICE_UPDATE_SUCCESS,
  GET_INVOICE_UPDATE_FAILED,

  REQ_INVOICE_UPDATE,
  REQ_INVOICE_UPDATE_SUCCESS,
  REQ_INVOICE_UPDATE_FAILED,
  REQ_INVOICE_UPDATE_RESET,

  SENT_INVOICE_CLIENT,
  SENT_INVOICE_CLIENT_SUCCESS,
  SENT_INVOICE_CLIENT_FAILED,

  GET_ENTITY,
  GET_ENTITY_SUCCESS,
  GET_ENTITY_FAILED,

  GET_ENTITY_BRAND,
  GET_ENTITY_BRAND_SUCCESS,
  GET_ENTITY_BRAND_FAILED,

  GET_PHASE,
  GET_PHASE_SUCCESS,
  GET_PHASE_FAILED,

  CHANGE_PHASE,
  CHANGE_PHASE_SUCCESS,
  CHANGE_PHASE_FAILED,

  GET_BRAND_PHASE,
  GET_BRAND_PHASE_SUCCESS,
  GET_BRAND_PHASE_FAILED,

  GET_BRAND_PHASE_HISTORY,
  GET_BRAND_PHASE_HISTORY_SUCCESS,
  GET_BRAND_PHASE_HISTORY_FAILED,

  UPLOAD_INVOICE_FILE,
  UPLOAD_INVOICE_FILE_SUCCESS,
  UPLOAD_INVOICE_FILE_FAILED,
  UPLOAD_INVOICE_FILE_RESET,

  GET_INVOICE_FILE,
  GET_INVOICE_FILE_SUCCESS,
  GET_INVOICE_FILE_FAILED,

  GET_AGREEMENT_LOGS,
  GET_AGREEMENT_LOGS_SUCCESS,
  GET_AGREEMENT_LOGS_FAILED,

  GET_INVOICE_FILELINK,
  GET_INVOICE_FILELINK_SUCCESS,
  GET_INVOICE_FILELINK_FAILED,
  GET_INVOICE_FILELINK_RESET
  
} from "./types";

const initialState = {
  accountlist: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },
  count: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },
  exportFile: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },

  updateInvoice: {
    isLoading: false,
    isCreated: false,
    isUpdated: false,
    data: [],
    error: null,
  },

  updateListInvoice: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },

  requestUpdateInvoice: {
    isLoading: false,
    isCreated: false,
    isUpdated: false,
    data: [],
    error: null,
  },

  sentInvoiceClient: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },

  entityList: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },

  entitybrandList: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },

  phaseitem: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },

  changephaseitem: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },

  brandPhase: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },

  phaseHistory: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },

  invoiceupload: {
    isLoading: false,
    isUploaded: false,
    data: [],
    error: null,
  },

  getinvoicedata: {
    isLoading: false,
    isUploaded: false,
    data: [],
    error: null,
  },

  agreementLogsdata: {
    isLoading: false,
    isUploaded: false,
    data: [],
    error: null,
  },

  invoiceFileDownload: {
    isLoading: false,
    isUploaded: false,
    data: [],
    error: null,
  },
};

const aggreementlist = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST:
      return {  
        ...state,
        accountlist: {
          ...state.create,
          isLoading: true,
          error: null,
        },
    };
    case GET_LIST_SUCCESS:
      return {
        ...state,
        accountlist: {
          data: action.payload,
        },
      };
    case GET_LIST_FAILED:
      return {
        ...state,
        accountlist: {
          ...state.create,
          isLoading: false,
          error: action.payload,
        },
      };

    case GET_COUNT:
      return {
        ...state,
        count: {
          ...state.create,
          isLoading: true,
          error: null,
        },
    };
    case GET_COUNT_SUCCESS:
      return {
        ...state,
        count: {
          data: action.payload,
        },
      };
    case GET_COUNT_FAILED:
      return {
        ...state,
        count: {
          ...state.create,
          isLoading: false,
          error: action.payload,
        },
      };
    
    case GET_EXPORT:
      return {
        ...state,
        exportFile: {
          ...state.create,
          isLoading: true,
          error: null,
        },
    };
    case GET_EXPORT_SUCCESS:
      return {
        ...state,
        exportFile: {
          data: action.payload,
        },
      };
    case GET_EXPORT_FAILED:
        return {
          ...state,
          exportFile: {
            ...state.create,
            isLoading: false,
            error: action.payload,
          },
        };

    case SET_INVOICE_UPDATE:
      return {
        ...state,
        updateInvoice: {
          ...state.create,
          isLoading: true,
          isUpdated: null,
          error: null,
        },
    };
    case SET_INVOICE_UPDATE_SUCCESS:
      return {
        ...state,
        updateInvoice: {
          data: action.payload,
          isUpdated: true,
        },
      };
    case SET_INVOICE_UPDATE_FAILED:
      return {
        ...state,
        updateInvoice: {
          ...state.create,
          isLoading: false,
          isUpdated: false,
          error: action.payload,
        },
      };
    case SET_INVOICE_UPDATE_RESET:
      return { ...state, updateInvoice: { ...initialState.updateInvoice } };
    
    
    case GET_INVOICE_UPDATE:
      return {
        ...state,
        updateListInvoice: {
          ...state.create,
          isLoading: true,
          isUpdated: null,
          error: null,
        },
    };
    case GET_INVOICE_UPDATE_SUCCESS:
      return {
        ...state,
        updateListInvoice: {
          data: action.payload,
          isUpdated: true,
        },
      };
    case GET_INVOICE_UPDATE_FAILED:
      return {
        ...state,
        updateListInvoice: {
          ...state.create,
          isLoading: false,
          isUpdated: false,
          error: action.payload,
        },
      };
      
    case REQ_INVOICE_UPDATE:
      return {
        ...state,
        requestUpdateInvoice: {
          ...state.create,
          isLoading: true,
          isUpdated: null,
          error: null,
        },
    };
    case REQ_INVOICE_UPDATE_SUCCESS:
      return {
        ...state,
        requestUpdateInvoice: {
          data: action.payload,
          isUpdated: true,
        },
      };
    case REQ_INVOICE_UPDATE_FAILED:
      return {
        ...state,
        requestUpdateInvoice: {
          ...state.create,
          isLoading: false,
          isUpdated: false,
          error: action.payload,
        },
      };
    case REQ_INVOICE_UPDATE_RESET:
      return { ...state, requestUpdateInvoice: { ...initialState.requestUpdateInvoice } };
      
    case SENT_INVOICE_CLIENT:
      return {
        ...state,
        sentInvoiceClient: {
          ...state.create,
          isLoading: true,
          isUpdated: null,
          error: null,
        },
    };
    case SENT_INVOICE_CLIENT_SUCCESS:
      return {
        ...state,
        sentInvoiceClient: {
          data: action.payload,
          isUpdated: true,
        },
      };
    case SENT_INVOICE_CLIENT_FAILED:
      return {
        ...state,
        sentInvoiceClient: {
          ...state.create,
          isLoading: false,
          isUpdated: false,
          error: action.payload,
        },
      };
   
    case GET_ENTITY:
      return {  
        ...state,
        entityList: {
          ...state.entityList,
          isLoading: true,
          error: null,
        },
    };
    case GET_ENTITY_SUCCESS:
      return {
        ...state,
        entityList: {
          ...state.entityList,
          data: action.payload,
        },
      };
    case GET_ENTITY_FAILED:
      return {
        ...state,
        entityList: {
          ...state.entityList,
          isLoading: false,
          error: action.payload,
        }, 
      };
  
    case GET_ENTITY_BRAND:
      return {  
        ...state,
        entitybrandList: {
          ...state.entitybrandList,
          isLoading: true,
          error: null,
        },
    };
    case GET_ENTITY_BRAND_SUCCESS:
      return {
        ...state,
        entitybrandList: {
          ...state.entitybrandList,
          data: action.payload,
        },
      };
    case GET_ENTITY_BRAND_FAILED:
      return {
        ...state,
        entitybrandList: {
          ...state.entitybrandList,
          isLoading: false,
          error: action.payload,
        }, 
      };
  
    case GET_PHASE:
      return {  
        ...state,
        phaseitem: {
          ...state.phaseitem,
          isLoading: true,
          error: null,
        },
    };
    case GET_PHASE_SUCCESS:
      return {
        ...state,
        phaseitem: {
          ...state.phaseitem,
          data: action.payload,
        },
      };
    case GET_PHASE_FAILED:
      return {
        ...state,
        phaseitem: {
          ...state.phaseitem,
          isLoading: false,
          error: action.payload,
        }, 
      };
  
    case CHANGE_PHASE:
      return {  
        ...state,
        changephaseitem: {
          ...state.changephaseitem,
          isLoading: true,
          error: null,
        },
    };
    case CHANGE_PHASE_SUCCESS:
      return {
        ...state,
        changephaseitem: {
          ...state.changephaseitem,
          data: action.payload,
        },
      };
    case CHANGE_PHASE_FAILED:
      return {
        ...state,
        changephaseitem: {
          ...state.changephaseitem,
          isLoading: false,
          error: action.payload,
        }, 
      };
  
    case GET_BRAND_PHASE:
      return {  
        ...state,
        brandPhase: {
          ...state.brandPhase,
          isLoading: true,
          error: null,
        },
    };
    case GET_BRAND_PHASE_SUCCESS:
      return {
        ...state,
        brandPhase: {
          ...state.brandPhase,
          data: action.payload,
        },
      };
    case GET_BRAND_PHASE_FAILED:
      return {
        ...state,
        brandPhase: {
          ...state.brandPhase,
          isLoading: false,
          error: action.payload,
        }, 
      };
  
    case GET_BRAND_PHASE_HISTORY:
      return {  
        ...state,
        phaseHistory: {
          ...state.phaseHistory,
          isLoading: true,
          error: null,
        },
    };
    case GET_BRAND_PHASE_HISTORY_SUCCESS:
      return {
        ...state,
        phaseHistory: {
          ...state.phaseHistory,
          data: action.payload,
        },
      };
    case GET_BRAND_PHASE_HISTORY_FAILED:
      return {
        ...state,
        phaseHistory: {
          ...state.phaseHistory,
          isLoading: false,
          error: action.payload,
        }, 
      };

    case UPLOAD_INVOICE_FILE:
      return {
        ...state,
        invoiceupload: {
          ...state.invoiceupload,
          isLoading: true,
          isUploaded: null,
          error: null,
        },
    };
    case UPLOAD_INVOICE_FILE_SUCCESS:
      return {
        ...state,
        invoiceupload: {
          ...state.invoiceupload,
          data: action.payload,
          isUploaded: true,
          isLoading: false,
        },
      };
    case UPLOAD_INVOICE_FILE_FAILED:
      return {
        ...state,
        invoiceupload: {
          ...state.invoiceupload,
          isLoading: false,
          isUploaded: false,
          error: action.payload,
        },
      };
    case UPLOAD_INVOICE_FILE_RESET:
      return { ...state, invoiceupload: { ...initialState.invoiceupload } };
    
    case GET_INVOICE_FILE:
      return {
        ...state,
        getinvoicedata: {
          ...state.getinvoicedata,
          isLoading: true,
          isUploaded: null,
          error: null,
        },
    };
    case GET_INVOICE_FILE_SUCCESS:
      return {
        ...state,
        getinvoicedata: {
          ...state.getinvoicedata,
          data: action.payload,
          isUploaded: true,
          isLoading: false,
        },
      };
    case GET_INVOICE_FILE_FAILED:
      return {
        ...state,
        getinvoicedata: {
          ...state.getinvoicedata,
          isLoading: false,
          isUploaded: false,
          error: action.payload,
        },
      };
    case GET_AGREEMENT_LOGS:
      return {
        ...state,
        agreementLogsdata: {
          ...state.agreementLogsdata,
          isLoading: true,
          error: null,
          isCreated: null
        },
    };
    case GET_AGREEMENT_LOGS_SUCCESS:
      return {
        ...state,
        agreementLogsdata: {
          data: action.payload,
          error: false,
          isCreated: true,
        },
      }; 
    case GET_AGREEMENT_LOGS_FAILED:
      return {
        ...state,
        agreementLogsdata: {
          ...state.agreementLogsdata,
          isLoading: false,
          isCreated: false,
          error: action.payload,
        },
      };
    case GET_INVOICE_FILELINK:
      return {
        ...state,
        invoiceFileDownload: {
          ...state.invoiceFileDownload,
          isLoading: true,
          error: null,
          isCreated: null
        },
    };
    case GET_INVOICE_FILELINK_SUCCESS:
      return {
        ...state,
        invoiceFileDownload: {
          data: action.payload,
          error: false,
          isCreated: true,
        },
      }; 
    case GET_INVOICE_FILELINK_FAILED:
      return {
        ...state,
        invoiceFileDownload: {
          ...state.invoiceFileDownload,
          isLoading: false,
          isCreated: false,
          error: action.payload,
        },
      };
    case GET_INVOICE_FILELINK_RESET:
      return { ...state, invoiceFileDownload: { ...initialState.invoiceFileDownload } };
    default:
      return state;
  }
};

export default aggreementlist;
